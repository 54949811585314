import React, { Component } from 'react';
import jsQR from 'jsqr';

class JsQRScanner extends Component {
    videoRef = React.createRef();
    canvasRef = React.createRef();

    componentDidMount() {
        navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } })
            .then(stream => {
                this.videoRef.current.srcObject = stream;
                this.videoRef.current.setAttribute("playsinline", true); // for iOS
                this.videoRef.current.play();
                requestAnimationFrame(this.tick);
            })
            .catch(err => {
                if (this.props.onError) {
                    this.props.onError(err);
                }
            });
    }

    componentWillUnmount() {
        if (this.videoRef.current && this.videoRef.current.srcObject) {
            const tracks = this.videoRef.current.srcObject.getTracks();
            tracks.forEach(track => track.stop());
        }
    }

    tick = () => {
        const video = this.videoRef.current;
        if (!video || video.readyState !== video.HAVE_ENOUGH_DATA) {
            requestAnimationFrame(this.tick);
            return;
        }
        // Use the full intrinsic video dimensions for scanning.
        const canvas = this.canvasRef.current;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const canvasContext = canvas.getContext("2d");
        canvasContext.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        const imageData = canvasContext.getImageData(0, 0, canvas.width, canvas.height);
        const code = jsQR(imageData.data, canvas.width, canvas.height);
        if (code) {
            if (this.props.onScan) {
                this.props.onScan(code.data);
            }
            // Remove return if continuous scanning is desired.
            return;
        }
        requestAnimationFrame(this.tick);
    };

    render() {
        return (
            <div className="qrScanner">
                <div
                    style={{
                        overflow: 'hidden',
                        position: 'relative',
                        width: '100%',
                        paddingTop: '100%' // creates a square container
                    }}
                >
                    <div
                        style={{
                            top: 0,
                            left: 0,
                            zIndex: 1,
                            boxSizing: 'border-box',
                            // Reduced border thickness (from 50px to 20px) makes the inner scanning region bigger.
                            border: '20px solid rgba(0, 0, 0, 0.3)',
                            boxShadow: 'rgba(255, 0, 0, 0.5) 0px 0px 0px 5px inset',
                            position: 'absolute',
                            width: '100%',
                            height: '100%'
                        }}
                    />
                    <video
                        ref={this.videoRef}
                        style={{
                            top: 0,
                            left: 0,
                            position: 'absolute',
                            overflow: 'hidden',
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                        }}
                        playsInline
                    />
                    <canvas
                        ref={this.canvasRef}
                        style={{ display: 'none' }}
                    />
                </div>
            </div>
        );
    }
}

export default JsQRScanner;

import React, { Component } from 'react';
import { confirmAlert } from "../utils/react-confirm-alert";
import { Redirect } from 'react-router-dom';
import Confetti from 'react-confetti';
import { unmountComponentAtNode } from "react-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import "../../styles/css/modalstyles.css";
import '../../styles/css/ConfirmAlertCustom.css';
import { base } from '../../base';
import { getDistance } from 'geolib';
import appBackgroundImage from '../../styles/images/background.png';
import Loading from '../utils/Loading';
import { getURLParameter, validateEmail } from "../utils/HelpfulFunction";
import JsQRScanner from '../utils/JsQRScanner';

class Main_App extends Component {
    constructor(props) {
        super(props);
        let userEmail;
        try {
            userEmail = localStorage.getItem('userEmail') || localStorage.getItem('tempToken') || false;
        } catch (e) {
            userEmail = false;
        }
        this.state = {
            userEmail: userEmail,
            width: 0,
            height: 0,
            startConfetti: false,
            currentGameId: null,
            squares: [],
            itemSelectedPopUpShown: false,
            itemSelectedToShow: {},
            result: 'No result',
            showQrScanner: false,
            userSquares: [],
            showSuccessfulRedeemModal: false,
            locationChecking: true,
            gameActive: false,
            loading: true,
            howToPlayModel: false
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleRules = this.toggleRules.bind(this);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    changeMilesToMeters(milesToConvert) {
        return milesToConvert * 1609.344;
    }

    getLocation() {
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                reject("Geolocation is not supported by your browser. Please change browsers to play!");
            } else {
                const toCheckLatitude = this.props.variables.latitude || 51.525;
                const toCheckLongitude = this.props.variables.longitude || 7.4575;
                navigator.geolocation.getCurrentPosition(
                    function (position) {
                        resolve(getDistance(
                            { latitude: position.coords.latitude, longitude: position.coords.longitude },
                            { latitude: toCheckLatitude, longitude: toCheckLongitude }
                        ));
                    },
                    (err) => {
                        if (err.message === "User denied Geolocation") {
                            reject("Position could not be determined because the browser does not have permission. Please go to your browser's settings to allow it access to your location");
                        } else {
                            console.log(err.message);
                            reject("An unknown error occurred, check your internet connection and try again");
                        }
                    }
                );
            }
        });
    }

    getLocationPermission() {
        const locationErrorTitle = "Location Error";
        this.getLocation().then(distance_meters => {
            localStorage.setItem('locationPermissions', "true");
            const allowed_distance = this.changeMilesToMeters(this.props.variables.acceptableDistance || 100);
            if (distance_meters <= allowed_distance) {
                this.setState({
                    modal: false,
                    loading: false,
                    locationChecking: false,
                    gameActive: true
                });
            } else {
                this.setState({ modal: false, loading: false });
                confirmAlert({
                    title: locationErrorTitle,
                    variables: this.props.variables,
                    message: "Too far from game area to participate!",
                    buttons: [
                        {
                            label: 'Retry',
                            onClick: () => {
                                this.checkUsersLocation();
                            }
                        }
                    ]
                });
            }
        }, error => {
            console.log(error);
            this.setState({ modal: false, loading: false });
            localStorage.setItem('locationPermissions', "false");
            if (typeof error !== "string") {
                error = error.message;
            }
            confirmAlert({
                title: locationErrorTitle,
                variables: this.props.variables,
                message: error,
                buttons: [
                    {
                        label: 'Retry',
                        onClick: () => {
                            this.checkUsersLocation();
                        }
                    }
                ]
            });
        });
    }

    checkUsersLocation() {
        const variables = this.props.variables;
        const locationPermissions = localStorage.getItem('locationPermissions');
        if (!this.props.variables.collectDistance) {
            this.setState({ locationChecking: false });
        } else if (locationPermissions === "false" || !locationPermissions) {
            const locationPermissionsHeader = variables.locationPermissionsHeader || "Location Permissions Required";
            const locationPermissionsBody = variables.locationPermissionsBody || "We need your location in order to play! We use this information to make your experience better";
            confirmAlert({
                title: locationPermissionsHeader,
                variables: variables,
                message: locationPermissionsBody,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            this.setState({ loading: true });
                            this.getLocationPermission();
                        }
                    }
                ],
            });
        } else {
            this.setState({ loading: true });
            this.getLocationPermission();
        }
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    logout() {
        localStorage.removeItem('userEmail');
        localStorage.removeItem('verifiedAge');
        localStorage.removeItem('birthday');
        localStorage.removeItem('locationPermissions');
        localStorage.removeItem('id_token');
        localStorage.removeItem('tempToken');
        this.setState({ userEmail: false });
    }

    toggleRules() {
        this.setState({ modalRules: !this.state.modalRules });
    }

    componentDidMount() {
        this.currentGameKeyRef = base.listenTo(`currentGame/id`, {
            context: this,
            then(key) {
                if (typeof key === "string") {
                    this.logUserActivity(key);
                    if (this.state.currentGameId) {
                        this.removeReactAlert();
                    }
                    this.setState({ currentGameId: key, startConfetti: false });
                }
            }
        });

        this.addedSquaresRef = base.listenTo(`currentGame/addedSquares`, {
            context: this,
            then(squares) {
                let freeSquare = false;
                for (let squareIndex in squares) {
                    const square = squares[squareIndex];
                    if (square.isFreeSquare) {
                        freeSquare = square;
                    }
                }
                this.setState({ squares: squares, freeSquare: freeSquare });
            }
        });

        this.mapImageRef = base.bindToState('currentGame/mapImage', {
            context: this,
            state: 'mapImage'
        });

        this.messagingArrayRef = base.bindToState('currentGame/messaging_array', {
            context: this,
            state: 'tierMessaging'
        });

        if (this.state.userEmail) {
            this.bindToUserAnswers();
        }

        this.gameActiveRef = base.listenTo(`currentGame/active`, {
            context: this,
            then(dataActive) {
                if (this.props.variables && this.props.variables.collectDistance && dataActive) {
                    this.checkUsersLocation();
                } else {
                    this.setState({
                        gameActive: dataActive,
                        locationChecking: false,
                        loading: false
                    }, () => {
                        if (dataActive) {
                            this.checkIfGetsFreeSquare();
                            const passedCode = this.props.passedCode || getURLParameter("passedCode");
                            const passedSquareId = this.props.passedSquareId || getURLParameter("passedSquareId");
                            if (passedCode && passedSquareId) {
                                this.submitCodeToRedeem({ id: passedSquareId }, passedCode);
                            }
                        }
                    });
                }
            }
        });
        if (this.props.tenantRulesVariables && this.props.tenantRulesVariables.showHowToPlayDuringLogin) {
            const response = localStorage.getItem('hasSeenHowToPlayPopUp');
            if (!response || response !== "true") {
                localStorage.setItem('hasSeenHowToPlayPopUp', "true");
                if (this.props.tenantRulesVariables.howToPlayText || !this.props.tenantRulesVariables.howToPlayLink) {
                    this.setState({ howToPlayModel: true });
                } else {
                    window.open(this.props.tenantRulesVariables.howToPlayLink, '_blank');
                }
            }
        }
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.scrollTo(0, 0);
    }

    logUserActivity(gameid) {
        const vm = this;
        if (gameid) {
            const userEmailToBase64 = btoa(this.state.userEmail);
            base.post('userGameHistory/' + userEmailToBase64 + `/${gameid}`, {
                data: gameid,
                then(err) {
                    if (!err) {
                        vm.setState({ alreadyUpdate: gameid });
                        console.log("user game logged!");
                    }
                }
            });
        }
    }

    bindToUserAnswers() {
        this.userSquaresRef = base.bindToState(`userSquares`, {
            context: this,
            queries: {
                orderByChild: 'uid',
                equalTo: btoa(this.state.userEmail)
            },
            asArray: true,
            state: 'userSquares'
        });
    }

    checkIfGetsFreeSquare() {
        const freeSquare = this.state.freeSquare;
        const userSquares = this.state.userSquares;
        if (freeSquare && (!userSquares || userSquares.length === 0)) {
            this.submitCodeToRedeem(freeSquare);
        }
    }

    removeReactAlert() {
        document.body.classList.remove('react-confirm-alert-body-element');
        const target = document.getElementById('react-confirm-alert');
        if (target) {
            unmountComponentAtNode(target);
            target.parentNode.removeChild(target);
        }
        const svg = document.getElementById('react-confirm-alert-firm-svg');
        if (svg) {
            svg.parentNode.removeChild(svg);
            document.body.children[0].classList.remove('react-confirm-alert-blur');
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        base.removeBinding(this.currentGameKeyRef);
        base.removeBinding(this.addedSquaresRef);
        base.removeBinding(this.gameActiveRef);
        base.removeBinding(this.mapImageRef);
        base.removeBinding(this.messagingArrayRef);
        if (this.userSquaresRef) {
            base.removeBinding(this.userSquaresRef);
        }
    }

    showItemSelectedPopUp(item) {
        this.setState({ itemSelectedToShow: item, itemSelectedPopUpShown: true });
    }

    showItemCompletedPopUp(item) {
        this.setState({ itemSelectedToShow: item, showSuccessfulRedeemModal: true });
    }

    activateQrCodeScanner() {
        this.setState({ showQrScanner: true, itemSelectedPopUpShown: false });
    }

    submitCodeToRedeem(squareToSubmit, passedCode = false) {
        let squareSubmitted;
        if (squareToSubmit && squareToSubmit.id) {
            squareSubmitted = squareToSubmit;
        }
        const item = squareSubmitted || this.state.itemSelectedToShow;
        const userAnswerObject = {};
        userAnswerObject['uid'] = btoa(this.state.userEmail);
        userAnswerObject['squareId'] = item.id;
        userAnswerObject['timeStamp'] = Date.now();
        userAnswerObject['code'] = this.state.code || passedCode || false;
        const vm = this;
        this.setState({ loading: true });

        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/checkIfGetsPrizeScavengerHunt`;

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(userAnswerObject)
        }).then(res => {
            vm.setState({ loading: false });
            Promise.resolve(res.json()).then(function (value) {
                let messageToShowHeader;
                let messageToShowBody;
                if (value.result === "wrong_code_entered") {
                    messageToShowHeader = "Incorrect Code";
                    messageToShowBody = "Make sure you scanned the right item and try again!";
                } else if (value.result === "prize_sent") {
                    vm.setState({ launchPrizeModal: true, startConfetti: true, winner: true });
                    vm.props.removePassedCode(vm);
                } else if (value && value.result === "already_got_that_square") {
                    messageToShowHeader = "Already Scanned";
                    messageToShowBody = "It looks like you’ve already scanned this tile.";
                    vm.props.removePassedCode(vm);
                } else if (value && (value.result === "no_prize_sent" || value.result === "answer_level_has_no_rewards")) {
                    vm.setState({ launchPrizeModal: true, startConfetti: true, winner: false });
                    vm.props.removePassedCode(vm);
                } else {
                    messageToShowHeader = "Unknown Error";
                    messageToShowBody = "Check your internet connect and try again. If you continue to have issues refresh the page";
                }
                if (messageToShowHeader && messageToShowBody) {
                    confirmAlert({
                        variables: vm.props.variables,
                        title: messageToShowHeader,
                        message: messageToShowBody,
                        buttons: [{ label: "OK" }]
                    });
                }
            });
        });
    }

    showMap() {
        this.setState({ showMap: !this.state.showMap });
    }

    handleScan = data => {
        if (data) {
            this.setState({
                showQrScanner: false,
                code: getURLParameter("passedcode", data) || data,
            }, () => {
                this.submitCodeToRedeem();
            });
        }
    };

    handleError = err => {
        if (err.message === "Permission denied" || err.message === "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.") {
            confirmAlert({
                variables: this.props.variables,
                title: "Need To Give Permission",
                message: "We need access to your camera to scan the codes. Please change your settings in your browser",
                buttons: [{ label: "OK" }]
            });
        } else if (err.message) {
            confirmAlert({
                variables: this.props.variables,
                title: "An Error Occurred",
                message: err.message,
                buttons: [{ label: "OK" }]
            });
        } else {
            confirmAlert({
                variables: this.props.variables,
                title: "Error Occurred",
                message: "An error occurred accessing your camera on your phone. If you are on iOS please make sure to use Safari",
                buttons: [{ label: "OK" }]
            });
        }
    };

    render() {
        const tenantVariables = this.props.variables || {};
        let addSearchParams = "";
        if (window.location.search) {
            addSearchParams = window.location.search;
        }
        if (!this.state.userEmail) {
            return (
                <Redirect to={{ pathname: "/login", search: addSearchParams }} />
            );
        } else if (!tenantVariables.doNotCollectEmail && !validateEmail(this.state.userEmail)) {
            this.logout();
        } else if (tenantVariables.doNotCollectEmail && validateEmail(this.state.userEmail)) {
            this.logout();
        }
        const verifiedAge = this.props.checkForAgeGate(tenantVariables);
        if (!verifiedAge) {
            return (
                <Redirect to={{ pathname: "/age_gate", search: addSearchParams }} />
            );
        }
        const tenantRules = this.props.tenantRulesVariables || {};
        const isRsl = process.env.REACT_APP_FIREBASE_PROJECT_ID === "rslfanscan" || process.env.REACT_APP_FIREBASE_PROJECT_ID === "sufanscan";
        const rulesInAppButtonText = tenantRules.rulesInAppButtonText || "Rules & Regs";
        let rulesShowInAppPopUpHeader = tenantRules.rulesShowInAppPopUpHeader;
        let rulesShowInAppPopUpText = tenantRules.rulesShowInAppPopUpText;
        let rulesPopUpText = tenantRules.rulesPopUpText;
        let rulesPopUpHeader = tenantRules.rulesPopUpHeader;
        const link = tenantRules.rulesAndRegsLink;
        const rulesShowInApp = tenantRules.rulesShowInApp || false;
        let howToPlayText = tenantRules.howToPlayText || "";
        if (rulesShowInApp) {
            if (!rulesShowInAppPopUpText && rulesPopUpText) {
                rulesShowInAppPopUpText = rulesPopUpText;
            }
            if (!rulesShowInAppPopUpHeader && rulesPopUpHeader) {
                rulesShowInAppPopUpHeader = rulesPopUpHeader;
            }
        }
        const logOutButtonColor = tenantVariables.logOutButtonColor || "#fff";
        const primaryColor = tenantVariables.primaryColor || "black";
        const secondaryColor = tenantVariables.secondaryColor || "white";
        const backgroundImage = tenantVariables.backgroundImage || appBackgroundImage;
        if (this.state.loading === true) {
            return (
                <Loading loading={this.state.loading} backgroundImage={backgroundImage} variables={tenantVariables} />
            );
        }

        const userSquares = this.state.userSquares || [];
        const userSquaresIdArray = [];
        if (userSquares.length > 0) {
            for (const userSquaresIndex in userSquares) {
                userSquaresIdArray.push(userSquares[userSquaresIndex].squareId);
            }
        }
        let completedHeader;
        let completedBody;
        let tierMessaging = this.state.tierMessaging || {};
        const tierMessages = tierMessaging[userSquares.length] || {};
        if (this.state.winner) {
            completedHeader = tierMessages.winningHeader || "Congrats!";
            completedBody = tierMessages.winningMessage || "You completed the square and won a prize! Check your email for your prize";
        } else {
            completedHeader = tierMessages.winningMissedHeader || "Congrats!";
            completedBody = tierMessages.winningMissedMessage || "You completed the square!";
        }
        if (howToPlayText === "<p><br></p>") {
            howToPlayText = "";
        }
        return (
            <div className="flex-container-home" style={{ backgroundImage: "url(" + backgroundImage + ")", scrollbarWidth: "none" }}>
                {this.state.startConfetti &&
                    <Confetti numberOfPieces={400} width={this.state.width} height={this.state.height} style={{ zIndex: 1 }} />
                }
                {isRsl &&
                    <div style={{ textAlign: "center", marginTop: 10, color: logOutButtonColor }}>
                        ©2023 KeyCorp. KeyBank Member FDIC
                    </div>
                }
                <div style={{ display: "flex", flexFlow: "row nowrap", justifyContent: "space-between", marginBottom: 10 }}>
                    <div style={{ display: typeof this.state.mapImage === "string" ? "" : "none", justifyContent: "flex-start" }}>
                        <button onClick={() => this.showMap()} className="btn btn-logout" style={{ color: logOutButtonColor, borderColor: logOutButtonColor, fontWeight: 700 }}>
                            {tenantVariables.showMapButtonText || "Show Map"}
                        </button>
                    </div>
                    <div style={{ visibility: rulesShowInApp ? "visible" : "hidden" }}>
                        <button className="btn btn-logout" onClick={() => { rulesShowInAppPopUpText ? this.toggleRules() : window.open(link, '_blank') }} style={{ color: logOutButtonColor, borderColor: logOutButtonColor, fontWeight: 700 }}>
                            {rulesInAppButtonText}
                        </button>
                    </div>
                    <div style={{ justifyContent: "flex-end", display: this.props.passedEmail && "none" }}>
                        <div className="btn btn-logout" style={{ color: logOutButtonColor, borderColor: logOutButtonColor, fontWeight: 700 }} onClick={() => this.logout()}>
                            LOG OUT
                        </div>
                    </div>
                </div>
                <center>
                    <img className="headerImage" src={tenantVariables.topHeaderImage} alt="" />
                </center>
                <div className="vertical_spacer" />
                {this.state.squares.length > 0 && this.state.gameActive ? (
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", maxWidth: 768, marginLeft: "auto", marginRight: "auto" }}>
                        {this.state.squares.sort((a, b) => a.order - b.order).map((item, index) => {
                            const itemAvailable = userSquaresIdArray.indexOf(item.id) === -1;
                            const isEvenNotDivisibleByThree = this.state.squares.length % 2 === 0 && this.state.squares.length % 3 !== 0;
                            let itemStyle = isEvenNotDivisibleByThree
                                ? { width: "45%", maxWidth: "45%", flexGrow: 1, margin: 5 }
                                : { width: "26%", maxWidth: "33%", flexGrow: 1, margin: 5 };
                            return (
                                <div key={index} style={itemStyle} onClick={() => itemAvailable ? this.showItemSelectedPopUp(item) : this.showItemCompletedPopUp(item)}>
                                    <img width="100%" src={itemAvailable ? item.image : tenantVariables.filledInIcon} alt="" />
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div>
                        <div className="headerText" style={{ color: tenantVariables.textColor, fontWeight: 700 }}>
                            {tenantVariables.noSquareHeader || "No Board Up"}
                        </div>
                        <div className="headerBody" style={{ color: tenantVariables.textColor, fontWeight: 700 }}>
                            {tenantVariables.noSquareBody || "Come back later to play"}
                        </div>
                    </div>
                )}
                <Modal isOpen={this.state.showMap} toggle={() => this.setState({ showMap: false })}>
                    <img style={{ padding: 10, margin: "auto", maxWidth: "100%", height: 'auto' }} src={this.state.mapImage} alt="" />
                </Modal>
                <Modal isOpen={this.state.itemSelectedPopUpShown} toggle={() => this.setState({ itemSelectedPopUpShown: false })} style={{ width: '90%' }}>
                    <div style={{ textAlign: "center", padding: 20 }}>
                        <div className="itemPopUpTitle">{this.state.itemSelectedToShow.squareName}</div>
                        <div>
                            <img width="80px" src={this.state.itemSelectedToShow.image} alt="" />
                        </div>
                        <div className="vertical_spacer" />
                        <button onClick={() => this.activateQrCodeScanner()} style={{ color: tenantVariables.secondaryColor, backgroundColor: tenantVariables.primaryColor }} className="btn btn-play">
                            Scan Code
                        </button>
                        <div style={{ marginTop: 10 }}>
                            <button onClick={() => this.setState({ itemSelectedPopUpShown: false })} style={{ color: tenantVariables.secondaryColor, backgroundColor: tenantVariables.primaryColor, fontSize: 12, fontWeight: 700 }} className="btn popUpButton">
                                Cancel
                            </button>
                        </div>
                        <div className="vertical_spacer" />
                        <span style={{ fontSize: "1.3rem", fontWeight: 400, lineHeight: 1.4 }} dangerouslySetInnerHTML={{ __html: this.state.itemSelectedToShow.squareInfoText }} />
                    </div>
                </Modal>
                <Modal isOpen={this.state.showSuccessfulRedeemModal} style={{ width: '90%' }}>
                    <div style={{ textAlign: "center", padding: 20 }}>
                        <div className="itemPopUpTitle" style={{ fontSize: 45 }}>{this.state.itemSelectedToShow.squareName}</div>
                        <div>
                            <img width="80px" src={this.state.itemSelectedToShow.image} alt="" />
                        </div>
                        <div className="vertical_spacer" />
                        <div className="popUpHeader" style={{ marginBottom: 5, fontSize: 20 }}>Completed! 👍</div>
                        <button onClick={() => { this.setState({ showSuccessfulRedeemModal: false, itemSelectedToShow: {}, startConfetti: false }) }} style={{ color: tenantVariables.secondaryColor, backgroundColor: tenantVariables.primaryColor }} className="btn btn-play">
                            OK
                        </button>
                    </div>
                </Modal>
                <Modal isOpen={this.state.launchPrizeModal} style={{ width: '90%' }}>
                    <div style={{ textAlign: "center", padding: 20 }}>
                        <div className="itemPopUpTitle">{completedHeader}</div>
                        <div className="popUpHeader" style={{ marginBottom: 5 }}>{completedBody}</div>
                        <button onClick={() => this.setState({ launchPrizeModal: false, startConfetti: false })} style={{ color: tenantVariables.secondaryColor, backgroundColor: tenantVariables.primaryColor }} className="btn btn-play">
                            OK
                        </button>
                    </div>
                </Modal>
                <Modal isOpen={this.state.modalRules} style={{ width: '90%' }} id="rulesModal">
                    <ModalHeader style={{ color: secondaryColor, padding: 10 }}>
            <span style={{ fontSize: 25, fontWeight: 700 }}>
              {rulesShowInAppPopUpHeader}
            </span>
                    </ModalHeader>
                    <ModalBody>
                        <center className="container-out">
                            <div className="question-box question-form">
                                <p style={{ width: "100%", height: 200, overflow: "scroll", padding: 10, borderBottom: "1px solid black", borderTop: "1px solid black", fontWeight: 200, fontSize: 14 }} dangerouslySetInnerHTML={{ __html: rulesShowInAppPopUpText }} />
                                <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleRules(); }} style={{ color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop: '20px', fontSize: '1.3 rem' }}>
                                    Dismiss
                                </button>
                            </div>
                        </center>
                    </ModalBody>
                </Modal>
                {this.state.showQrScanner &&
                    <div className="fullscreen">
                        <div style={{ maxWidth: "100%" }}>
                            <img width="80px" src={this.state.itemSelectedToShow.image} alt="" />
                            <h4 className="aboveQrcodeText">{this.state.itemSelectedToShow.squareName}</h4>
                            <h4 className="aboveQrcodeText">{tenantVariables.scannerText || "Use this scanner to scan the item QR code"}</h4>
                            <JsQRScanner onError={this.handleError} onScan={this.handleScan} />
                            <div className="vertical_spacer" />
                            <button style={{ color: tenantVariables.secondaryColor, backgroundColor: tenantVariables.primaryColor, fontWeight: 700 }} className="btn popUpButton" onClick={() => this.setState({ showQrScanner: false })}>
                                CANCEL
                            </button>
                        </div>
                    </div>
                }
                <Modal isOpen={this.state.howToPlayModel} style={{ width: '90%' }} id="myModal">
                    <ModalHeader />
                    <ModalBody style={{ textAlign: "center" }}>
                        <div className="container-out" style={{ paddingLeft: 20, paddingRight: 20 }}>
                            <span dangerouslySetInnerHTML={{ __html: howToPlayText }} />
                            <button className="btn btn-play" onClick={() => { this.setState({ howToPlayModel: false }); }} style={{ color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop: '20px', fontSize: '1.3 rem' }}>
                                OK
                            </button>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{ borderTop: 'none' }} />
                </Modal>
            </div>
        );
    }
}

export default Main_App;
